var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('el-card',{staticStyle:{"height":"100%"}},[_c('el-button',{staticClass:"el-icon-back",staticStyle:{"margin-bottom":"10px"},on:{"click":_vm.go_out}},[_vm._v("返回")]),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('span',[_vm._v("变式号："+_vm._s(_vm.variant_number))]),_c('span',{staticClass:"topic_content"},[_vm._v(" 题目内容： "),_c('el-button',{on:{"click":_vm.show_subject}},[_vm._v("查看")])],1)]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","cell-style":{ textAlign: 'center' },"header-cell-style":{textAlign: 'center'}},on:{"row-click":_vm.clickTable}},[_c('el-table-column',{attrs:{"fixed":"","prop":"name","label":"学生姓名","width":"150"}}),_vm._l((_vm.tableArr),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item}},[_c('el-table-column',{attrs:{"label":"布置时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.list),function(itm,inx){return (inx == index)?_c('div',{key:inx},[_vm._v(_vm._s(itm.state == 0?itm.startTime:'未布置'))]):_vm._e()})}}],null,true)}),_c('el-table-column',{attrs:{"label":"1次对"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.list),function(itm,inx){return (inx == index)?_c('div',{key:inx},[_vm._v(_vm._s(itm.onceCorrectCount==0?'':'√'))]):_vm._e()})}}],null,true)}),_c('el-table-column',{attrs:{"label":"第2次对"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.list),function(itm,inx){return (inx == index)?_c('div',{key:inx},[_vm._v(_vm._s(itm.twiceCorrectCount==0?'':'√'))]):_vm._e()})}}],null,true)}),_c('el-table-column',{attrs:{"label":"2次都错"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.list),function(itm,inx){return (inx == index)?_c('div',{key:inx},[_vm._v(_vm._s(itm.twiceIncorrectCount==0?'':'√'))]):_vm._e()})}}],null,true)})],1)}),_c('el-table-column',{attrs:{"fixed":"right","prop":"accuracy","label":"最后一次的内化率","width":"150"}})],2),_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"page-size":_vm.page.pageSize,"layout":"total, prev, pager, next","total":_vm.page.total,"background":""},on:{"current-change":_vm.handleCurrentChange}}),_c('div',{staticStyle:{"margin":"20px 0"}},[_c('span',[_vm._v("姓名："+_vm._s(_vm.student_name))])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData_student,"border":"","cell-style":{ textAlign: 'center' },"header-cell-style":{textAlign: 'center'}}},[_c('el-table-column',{attrs:{"prop":"order","label":"布置任务的次数"}}),_c('el-table-column',{attrs:{"prop":"startTime","label":"布置时间"}}),_c('el-table-column',{attrs:{"prop":"accuracy","label":"本次内化率"}}),_vm._l((_vm.tableArr_student),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.children),function(itm,inx){return (item == itm.name)?_c('div',{key:inx},[_vm._v(_vm._s(itm.title==0?'×':'√'))]):_vm._e()})}}],null,true)})})],2),_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"page-size":_vm.pageDetails.pageSize,"layout":"total, prev, pager, next","total":_vm.pageDetails.total,"background":""},on:{"current-change":_vm.handleCurrentChangeDetails}})],1),_c('el-dialog',{attrs:{"title":"题目描述","visible":_vm.subject_dialog},on:{"update:visible":function($event){_vm.subject_dialog=$event}}},[_c('ul',_vm._l((_vm.descriptions),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm.descriptionType[item.descriptionType])+" "),_c('div',{class:item.descriptionType == 6 ? 'des_p' : ''},[_c('b',{staticClass:"des",domProps:{"innerHTML":_vm._s(item.description)}})])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }