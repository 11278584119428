<!-- 统计表格 -->
<template>
  <div style="height:100%">
    <el-scrollbar style="height:100%">
      <el-card style="height:100%">
        <el-button style="margin-bottom:10px" @click="go_out" class="el-icon-back">返回</el-button>
        <div style="margin-bottom:10px">
          <span>变式号：{{variant_number}}</span>
          <span class="topic_content">
            题目内容：
            <el-button @click="show_subject">查看</el-button>
          </span>
        </div>
        <!-- 班级变式表格 -->
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          @row-click="clickTable"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{textAlign: 'center'}"
        >
          <el-table-column fixed prop="name" label="学生姓名" width="150"></el-table-column>
          <el-table-column :label="item" v-for="(item,index) in tableArr" :key="index">
            <el-table-column label="布置时间">
              <template slot-scope="{row}">
                <div
                  v-for="(itm,inx) in row.list"
                  :key="inx"
                  v-if="inx == index"
                >{{itm.state == 0?itm.startTime:'未布置'}}</div>
              </template>
            </el-table-column>
            <el-table-column label="1次对">
              <template slot-scope="{row}">
                <div
                  v-for="(itm,inx) in row.list"
                  :key="inx"
                  v-if="inx == index"
                >{{itm.onceCorrectCount==0?'':'√'}}</div>
              </template>
            </el-table-column>
            <el-table-column label="第2次对">
              <template slot-scope="{row}">
                <div
                  v-for="(itm,inx) in row.list"
                  :key="inx"
                  v-if="inx == index"
                >{{itm.twiceCorrectCount==0?'':'√'}}</div>
              </template>
            </el-table-column>
            <el-table-column label="2次都错">
              <template slot-scope="{row}">
                <div
                  v-for="(itm,inx) in row.list"
                  :key="inx"
                  v-if="inx == index"
                >{{itm.twiceIncorrectCount==0?'':'√'}}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column fixed="right" prop="accuracy" label="最后一次的内化率" width="150"></el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
          background
          style="margin-top:10px"
        ></el-pagination>
        <div style="margin:20px 0">
          <span>姓名：{{student_name}}</span>
        </div>
        <!-- 学生详情表格 -->
        <el-table
          :data="tableData_student"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{textAlign: 'center'}"
        >
          <el-table-column prop="order" label="布置任务的次数"></el-table-column>
          <el-table-column prop="startTime" label="布置时间"></el-table-column>
          <el-table-column prop="accuracy" label="本次内化率"></el-table-column>
          <el-table-column :label="item" v-for="(item,index) in tableArr_student" :key="index">
            <template slot-scope="{row}">
              <div
                v-for="(itm,inx) in row.children"
                :key="inx"
                v-if="item == itm.name"
              >{{itm.title==0?'×':'√'}}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChangeDetails"
          :page-size="pageDetails.pageSize"
          layout="total, prev, pager, next"
          :total="pageDetails.total"
          background
          style="margin-top:10px"
        ></el-pagination>
      </el-card>
      <el-dialog title="题目描述" :visible.sync="subject_dialog">
        <ul>
          <li v-for="(item, i) in descriptions" :key="i">
            {{ descriptionType[item.descriptionType] }}
            <div :class="item.descriptionType == 6 ? 'des_p' : ''">
              <b v-html="item.description" class="des"></b>
            </div>
          </li>
        </ul>
      </el-dialog>
    </el-scrollbar>
  </div>
</template>

<script>
import { StudyStatisticStudent, StudentStatisticsDetails } from '../../api/api'
import moment from "moment";
export default {
  data () {
    return {
      tableData: [],//表格数据
      tableArr: [],//表格标题数据
      page: {
        pageSize: 5,
        pageNum: 1,
        total: 0
      },
      pageDetails: {
        pageSize: 5,
        pageNum: 1,
        total: 0
      },
      tableData_student: [],//学生详情表数据
      tableArr_student: [],//学生详情标题数据
      variant_number: '',//变式号
      subject_dialog: false,//题目描述弹框
      descriptions: [],//题目描述标题
      descriptionType: this.parameter.descriptionType(),
      student_name: '',//学生名
      userId: '',//用户id
      taskId: ''//变式id
    }
  },

  components: {},
  mounted () {
    this.loaddata()
    this.variant_number = this.$route.query.variantNumber
  },
  methods: {
    // 查看题目内容
    show_subject () {
      this.subject_dialog = true
      let param = {
        'activeId': 592,
        'caseId': this.$route.query.id,
        'caseType': 0,
        'isComplete': 0
      }
      this.$api.page.getCase(param).then(res => {
        if (res.data.code == 0) {
          this.descriptions = res.data.data.descriptions;
        }
      })
    },
    loaddata () {
      let param = {
        id: this.$route.query.taskId,
        pageSize: this.page.pageSize,
        pageNum: this.page.pageNum
      }
      StudyStatisticStudent(param).then(res => {
        this.tableArr = []
        let arr = []
        res.data.list.map((item, index) => {
          if (this.student_name == '') {
            this.student_name = item.name
            this.userId = item.userId
            this.taskId = item.taskId
          }
          if (arr.length == 0) {
            arr = item.list
          } else if (arr.length < item.list.length) {
            arr = item.list
          }
          item.accuracy = '0.00%'
          item.list.forEach(itm => {
            itm.startTime = this.timestampToTime(Date.parse(itm.startTime))
            if (itm.state == 0) {
              item.accuracy = (itm.accuracy * 100).toFixed(2) + '%'
            }
          })
        })
        arr.forEach((item, index) => {
          this.tableArr.push(`第${index + 1}次布置`)
        })
        this.tableData = res.data.list
        this.page.total = res.data.total
        this.load_student_data(res.data.list[0])
      })
    },
    load_student_data (val) {
      this.tableArr_student = []
      let param = {
        userId: this.userId,
        id: this.$route.query.taskId,
        pageSize: this.pageDetails.pageSize,
        pageNum: this.pageDetails.pageNum
      }
      StudentStatisticsDetails(param).then(res => {
        if (res.code == 0) {
          res.data.list.sort((atime, btime) => {
            return Date.parse(atime.startTime) - Date.parse(btime.startTime)
          })
          let arr = []
          val.list.forEach((valItem, valIndex) => {
            if (valItem.state == 0) {
              arr.push(res.data.list[valIndex])
            }
          })
          arr.map((item, index) => {
            item.startTime = this.timestampToTime(Date.parse(item.startTime))
            item.order = `第${index + 1}次`
            item.accuracy = Number(item.accuracy * 100).toFixed(2) + '%'
            if (item.names) {
              this.tableArr_student = item.names
              item.children = []
              item.names.map((itm, inde) => {
                let obj = {}
                item.answers.map((it, ind) => {
                  if (inde == ind) {
                    obj['name'] = itm
                    obj['title'] = it
                    item.children.push(obj)
                  }
                })
              })
            }
          })
          this.tableData_student = arr
          this.pageDetails.total = res.data.total
        }
      })
    },
    // 返回
    go_out () {
      this.$router.go(-1)
    },
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.loaddata()
    },
    handleCurrentChangeDetails (val) {
      this.pageDetails.pageNum = val
      this.load_student_data()
    },
    clickTable (val) {
      this.userId = val.userId
      this.taskId = val.taskId
      this.load_student_data(val)
      this.student_name = val.name
    },
    // 转换时间
    timestampToTime (timestamp) {
      let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Y + M + D + h + m + s;
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.green {
  color: green;
}
.des_p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
  background-color: #d8e8ff;
  border-radius: 5px;
  padding: 10px 0;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}
ul {
  li {
    font-size: 12px;
    font-weight: 700;
    color: #323232;
    line-height: 17px;
    margin-bottom: 10px;
  }
  li:before {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    background: red; //这里不是color
    vertical-align: middle;
    margin-right: 5px;
  }
  p {
    display: block;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    line-height: 17px;
  }
  i {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    line-height: 17px;
    margin-bottom: 20px;
  }
  .text-red {
    color: red;
  }
}
.el-pagination {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translate(-50%);
}
.topic_content {
  display: inline-block;
  margin-left: 50px;
}
</style>
